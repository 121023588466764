import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import typingVideo from '../assets/video/typing.mp4';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    box: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
}));

const Home = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12} md={12} className={classes.box}>
                <video
                    autoPlay="autoplay"
                    loop="loop"
                    muted
                    className={classes.Video}
                >
                    <source src={typingVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Grid>
        </Grid>
    );
};

export default Home;
